import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertInterface } from '../models/alert.interface';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  public isDisplayed$: Observable<AlertInterface | null> = new Observable();
  private isDisplayed: BehaviorSubject<AlertInterface | null> = new BehaviorSubject<AlertInterface | null>(null);

  constructor(
    private readonly translateService: TranslateService,
  ) {
    this.isDisplayed$ = this.isDisplayed.asObservable();
  }

  public display(type: 'error' | 'warning' | 'info' | 'success', text: string, func?: Function, funcLabel?: string): void {
    this.isDisplayed.next({ type, text: this.translateService.instant(text), func, funcLabel });
  }

  public hide(): void {
    this.isDisplayed.next(null);
  }
}
